import React from 'react';
import './Hodiny.css';

const lectures = [
  {
    id: 1,
    title: "Úvod do algoritmizace",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina1.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/kcAwf43T8qmK74m" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },

  {
    id: 2,
    title: "Základní algoritmy, binární vyhledávání",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina2.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/i7JHKn6er9Rgwto" },
      { type: "Úkol", color: "yellow", url: "https://seminar.stepankovar.cz/ukoly/#assignment-1" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },

  {
    id: 3,
    title: "Binární vyhledávání, euklidův algoritmus",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina3.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/XMQHgrDNyZ7rfTd" },
      { type: "Úkol", color: "yellow", url: "https://seminar.stepankovar.cz/ukoly/#assignment-2" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },

  {
    id: 4,
    title: "Euklidův algoritmus, Eratosthenovo síto, Rekurze",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina4.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/tjSCy3TZxnD9bSN" },
      { type: "Úkol", color: "yellow", url: "https://seminar.stepankovar.cz/ukoly/#assignment-3" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },

  {
    id: 5,
    title: "Třídicí algoritmy",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina5.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/f9FWPzttXrCfonp" },
      { type: "Úkol", color: "yellow", url: "https://seminar.stepankovar.cz/ukoly/#assignment-4" }
    ],
    tags: [
      { name: "Selection sort", url: "https://nextcloud.stepankovar.cz/s/oyizEmR9bHMca5H" },
      { name: "Bubble sort", url: "https://nextcloud.stepankovar.cz/s/R7W9ySx2p8wSZzH" },
      { name: "Insertion sort", url: "https://nextcloud.stepankovar.cz/s/qaQjcZAXng38pao" },
      ]
  },
  {
    id: 6,
    title: "Třídicí algoritmy 2",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina6.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/kipgxoSq26bjYHy" },
      { type: "Úkol", color: "yellow", url: "https://seminar.stepankovar.cz/ukoly/#assignment-5" }
    ],
    tags: [
      ]
  },

  {
    id: 7,
    title: "OOP, Datové struktury",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina7.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/KEm4HJq9afsCJPX" },
    ],
    tags: [
      { name: "Spojový seznam operace", url: "https://nextcloud.stepankovar.cz/s/Lc5oa55MYsGYL4y" },
      ]
  },

  {
    id: 8,
    title: "Datové struktury 2",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina8.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/WTybCZ43bLdss2Z" },
    ],
    tags: [
      { name: "Halda - operace", url: "https://nextcloud.stepankovar.cz/s/F7pEDxB8xe2jcsF" },
      { name: "HeapSort", url: "https://nextcloud.stepankovar.cz/s/EbgHQdsLL4Hq4Zs" },
      ]
  },

  {
    id: 9,
    title: "Datové struktury 3",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina9.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/ARRSezP2ZwZGE3S" },
    ],
    tags: [
      { name: "Binární vyhledávací strom - operace", url: "https://nextcloud.stepankovar.cz/s/JbyYJgctoSDAzEp" },
      ]
  },

  {
    id: 10,
    title: "Úvod do teorie grafů",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina10.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/kDiGgCzx6BajxJZ" },
    ],
    tags: [
      ]
  },

  {
    id: 11,
    title: "Prohledávání do šířky",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina11.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/tjPCLrwqHkyoAXs" },
    ],
    tags: [
      { name: "Pathfinding", url: "https://pathfinding.stepankovar.cz/" },
      ]
  },

  {
    id: 12,
    title: "Prohledávání do hloubky",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina12.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.stepankovar.cz/s/ZsjmtqP6SypZxN4" },
    ],
    tags: [
      { name: "N-Queens", url: "assignments/n_queens_template.py" },
      ]
  },
  // Add more lectures here...
];

function TagButton({ tag }) {
  // Determine if the URL is a local file (doesn't start with http or https)
  const isLocal = !/^https?:\/\//i.test(tag.url);
  
  return (
    <a 
      href={tag.url} 
      className="tag-button"
      // Conditionally add the download attribute for local files
      {...(isLocal ? { download: true } : {})}
      // Optionally, open external links in a new tab
      target={!isLocal ? "_blank" : "_self"}
      rel={!isLocal ? "noopener noreferrer" : undefined}
    >
      {tag.name}
    </a>
  );
}
function MaterialButton({ material }) {
  return (
    <a href={material.url} className={`material-button ${material.color}`}>
      {material.type}
    </a>
  );
}

function LectureItem({ lecture }) {
  return (
    <div className="lecture-item">
      <h3>{lecture.id}. {lecture.title}</h3>
      <div className="materials">
        {lecture.materials.map((material, index) => (
          <MaterialButton key={index} material={material} />
        ))}
      </div>
      <div className="tags">
        {lecture.tags.map((tag, index) => (
          <TagButton key={index} tag={tag} />
        ))}
      </div>
    </div>
  );
}

function Hodiny() {
  return (
    <section id="hodiny" className="section">
      <h2>Hodiny</h2>
      <div className="lecture-list">
        {lectures.map(lecture => (
          <LectureItem key={lecture.id} lecture={lecture} />
        ))}
      </div>
    </section>
  );
}

export default Hodiny;
