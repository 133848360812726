import React, { useState } from 'react';
import './homework-styles.css';
import { ChevronDown, ChevronUp, Calendar, Award, FileText } from 'lucide-react';

const HomeworkItem = ({ id, title, descriptionElements, deadline, points, templateLink }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div id={`assignment-${id}`} className="border rounded-lg mb-4 overflow-hidden">
        <div 
        className="flex justify-between items-center p-4 bg-gray-100 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        >
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center space-x-4">
        <span className="flex items-center">
        <Calendar className="w-4 h-4 mr-1" />
        {deadline}
        </span>
        <span className="flex items-center">
        <Award className="w-4 h-4 mr-1" />
        {points} bodů
        </span>
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </div>
        </div>
        {isExpanded && (
            <div className="p-4">
                {descriptionElements.map((element, index) => (
                    <React.Fragment key={index}>
                        {element.type === 'p' && <p className={element.className}>{element.content}</p>}
                        {element.type === 'h3' && <h3 className={element.className}>{element.content}</h3>}
                        {element.type === 'code' && (
                            <code className="description-code">{element.content}</code>
                        )}
                    </React.Fragment>
                ))}
                {templateLink && (
                    <a 
                        href={`/${templateLink}`} // Adding a leading slash here makes it an absolute URL
                        download
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-blue-600 hover:underline"
                    >
                        <FileText className="w-4 h-4 mr-1 template" />
                        Začněte s šablonou
                    </a>
                )}
            </div>
)}
        </div>
    );
};

const Ukoly = () => {
    const homeworks = [
        {
            id: 1,
            title: "Úkol 1: Součin prvků až na aktuální",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'Cílem tohoto úkolu je vytvořit funkci ' },
                { type: 'code', content: 'product_except_self' },
                { type: 'p', className: 'description p', content: ', která přijme seznam celých čísel a vrátí nový seznam, kde každý prvek na indexu i obsahuje součin všech čísel ze vstupního seznamu kromě čísla na indexu i. Při řešení tohoto problému je zakázáno používat operaci dělení.' },
                { type: 'h3', className: 'description h3', content: 'Popis funkce:' },
                { type: 'p', className: 'description p', content: 'Funkce ' },
                { type: 'code', content: 'product_except_self' },
                { type: 'p', className: 'description p', content: ' by měla projít seznam ' },
                { type: 'code', content: 'nums' },
                { type: 'p', className: 'description p', content: ' a pro každý prvek vypočítat produkt ostatních prvků. Výsledný seznam by měl mít stejnou délku jako vstupní seznam a na každé pozici ' },
                { type: 'code', content: 'i' },
                { type: 'p', className: 'description p', content: ' by měl obsahovat produkt všech prvků seznamu ' },
                { type: 'code', content: 'nums' },
                { type: 'p', className: 'description p', content: ' kromě ' },
                { type: 'code', content: 'nums[i]' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Vstup: ' },
                { type: 'code', content: 'nums = [1, 2, 3, 4]' },
                { type: 'p', className: 'description p', content: ' Výstup: ' },
                { type: 'code', content: '[24, 12, 8, 6]' },
                { type: 'p', className: 'description p', content: ' V tomto příkladu, pro první prvek (1), výstupní hodnota je součin 2 * 3 * 4 = 24, a tak dále pro ostatní prvky.' },
                { type: 'h3', className: 'description h3', content: 'Asymptotická složitost:' },
                { type: 'p', className: 'description p', content: 'Uveďte v kódu také, v jakém asymptoticky času pracuje váš algoritmus. Nyní se ještě nemusíte snažit, aby váš kód byl co nejefektivnější. Zajímá mě jen analýza vašeho kódu.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Po napsání funkce byste měli ověřit její správnost spuštěním připravených unit testů, které ověří funkčnost kódu v různých scénářích.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci ' },
                { type: 'code', content: 'product_except_self' },
                { type: 'p', className: 'description p', content: ' spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "29. 9. 2024",
            points: 5,
            templateLink: "assignments/product_exept_self.py"
        },
        {
            id: 2,
            title: "Úkol 2: Najdi nejbližší větší prvek",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu je vaším cílem implementovat funkci ' },
                { type: 'code', content: 'find_nearest_bigger' },
                { type: 'p', className: 'description p', content: ', která přijme seřazený seznam celých čísel ' },
                { type: 'code', content: 'arr' },
                { type: 'p', className: 'description p', content: ' a celé číslo ' },
                { type: 'code', content: 'k' },
                { type: 'p', className: 'description p', content: ', a vrátí nejmenší prvek v seznamu, který je větší než ' },
                { type: 'code', content: 'k' },
                { type: 'p', className: 'description p', content: '. Pokud takový prvek neexistuje, funkce by měla vrátit ' },
                { type: 'code', content: 'None' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Požadavek:' },
                { type: 'p', className: 'description p', content: 'Funkci musíte implementovat pomocí algoritmu binárního vyhledávání, aby dosáhla časové složitosti ' },
                { type: 'code', content: 'O(log n)' },
                { type: 'p', className: 'description p', content: '. Je zakázáno používat vestavěné funkce pro binární vyhledávání, jako je modul ' },
                { type: 'code', content: 'bisect' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Vstup: ' },
                { type: 'code', content: 'arr = [1, 3, 5, 7, 9]' },
                { type: 'p', className: 'description p', content: ', ' },
                { type: 'code', content: 'k = 4' },
                { type: 'p', className: 'description p', content: ' Výstup: ' },
                { type: 'code', content: '5' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Po implementaci funkce byste měli ověřit její správnost spuštěním připravených unit testů, které pokrývají různé scénáře. Také otestují, zda je vaše řešení dostatečně efektivní - lineární vyhledávání neprojde.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci ' },
                { type: 'code', content: 'find_nearest_bigger' },
                { type: 'p', className: 'description p', content: ' spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "13. 10. 2024",
            points: 5,
            templateLink: "assignments/nearest_bigger.py"
        },
        {
            id: 3,
            title: "Úkol 3: Největší společný dělitel více čísel",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'Cílem tohoto úkolu je vytvořit funkci ' },
                { type: 'code', content: 'gcd_multiple' },
                { type: 'p', className: 'description p', content: ', která přijme seznam celých čísel a vrátí největší společný dělitel (NSD) všech čísel v seznamu. Vaším úkolem je implementovat Euklidův algoritmus, a zároveň ho modifikovat tak, aby fungoval pro více než dvě čísla.' },
                { type: 'h3', className: 'description h3', content: 'Popis funkce:' },
                { type: 'p', className: 'description p', content: 'Funkce ' },
                { type: 'code', content: 'gcd_multiple' },
                { type: 'p', className: 'description p', content: ' přijme seznam celých čísel ' },
                { type: 'code', content: 'nums' },
                { type: 'p', className: 'description p', content: ' a vrátí jediné celé číslo, které je největším společným dělitelem všech čísel v seznamu. Vaším cílem je navrhnout efektivní způsob, jak upravit Euklidův algoritmus tak, aby fungoval pro libovolný počet čísel. Můžete začít výpočtem NSD dvou čísel a tento výsledek následně použít k výpočtu NSD s dalšími čísly.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Vstup: ' },
                { type: 'code', content: 'nums = [60, 48, 36]' },
                { type: 'p', className: 'description p', content: ' Výstup: ' },
                { type: 'code', content: '12' },
                { type: 'p', className: 'description p', content: ' V tomto příkladu, největším společným dělitelem čísel 60, 48 a 36 je 12.' },
                { type: 'h3', className: 'description h3', content: 'Asymptotická složitost:' },
                { type: 'p', className: 'description p', content: 'Uveďte v kódu také, v jakém asymptoticky času pracuje váš algoritmus. Zaměřte se na to, jak roste časová náročnost s počtem čísel a velikostí vstupních hodnot.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Po napsání funkce ověřte její správnost spuštěním připravených unit testů, které ověří funkčnost kódu pro různé vstupy. Váš kód by měl projít všemi testy, než ho odevzdáte.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci ' },
                { type: 'code', content: 'gcd_multiple' },
                { type: 'p', className: 'description p', content: ' spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "20. 10. 2024",
            points: 5,
            templateLink: "assignments/gcd_multiple.py"
        },
        {
            id: 4,
            title: "Úkol 4: Seřazení lichých a sudých čísel",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'Cílem tohoto úkolu je vytvořit funkci ' },
                { type: 'code', content: 'sort_odd_even' },
                { type: 'p', className: 'description p', content: ', která přijme seznam celých čísel a seřadí jej následujícím způsobem: lichá čísla budou seřazena vzestupně, zatímco sudá čísla sestupně. Funkce zachová původní relativní pozice lichých a sudých čísel v seznamu.' },
                { type: 'h3', className: 'description h3', content: 'Popis funkce:' },
                { type: 'p', className: 'description p', content: 'Funkce ' },
                { type: 'code', content: 'sort_odd_even' },
                { type: 'p', className: 'description p', content: ' přijme seznam celých čísel ' },
                { type: 'code', content: 'nums' },
                { type: 'p', className: 'description p', content: ' a vrátí seznam, ve kterém jsou lichá čísla seřazena vzestupně a sudá čísla sestupně. Relativní pozice čísel (lichých i sudých) vůči jejich původnímu pořadí zůstávají zachovány. Budete muset implementovat třídicí algoritmus a zároveň modifikovat jeho část, aby správně třídil sudá čísla sestupně.' },
                { type: 'p', className: 'description p', content: 'Nepoužívejte prosím žádné built-in třídicí algoritmy - sort() atp.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Vstup: ' },
                { type: 'code', content: 'nums = [5, 8, 3, 2, 9, 6, 4, 7]' },
                { type: 'p', className: 'description p', content: ' Výstup: ' },
                { type: 'code', content: '[3, 8, 5, 6, 7, 4, 2, 9]' },
                { type: 'p', className: 'description p', content: ' V tomto příkladu jsou lichá čísla (5, 3, 9, 7) seřazena vzestupně na ' },
                { type: 'code', content: '[3, 5, 7, 9]' },
                { type: 'p', className: 'description p', content: ' a sudá čísla (8, 2, 6, 4) sestupně na ' },
                { type: 'code', content: '[8, 6, 4, 2]' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Asymptotická složitost:' },
                { type: 'p', className: 'description p', content: 'Uveďte v kódu, v jakém asymptoticky času pracuje váš algoritmus. Zaměřte se na to, jak se časová složitost mění s velikostí seznamu a volbou třídicího algoritmu.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Po napsání funkce ověřte její správnost spuštěním připravených unit testů, které ověří funkčnost kódu pro různé vstupy. Váš kód by měl projít všemi testy, než ho odevzdáte.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci ' },
                { type: 'code', content: 'sort_odd_even' },
                { type: 'p', className: 'description p', content: ' spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "3. 11. 2024",
            points: 5,
            templateLink: "assignments/sort_odd_even.py"
        },
        {
            id: 5,
            title: "Úkol 5: Merge sort s top k prvky",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu budete implementovat funkci ' },
                { type: 'code', content: 'merge_sort_top_k' },
                { type: 'p', className: 'description p', content: ', která seřadí pole pomocí algoritmu merge sort a vrátí ' },
                { type: 'code', content: 'k' },
                { type: 'p', className: 'description p', content: ' největších prvků.' },
                { type: 'h3', className: 'description h3', content: 'Popis funkce:' },
                { type: 'p', className: 'description p', content: 'Funkce ' },
                { type: 'code', content: 'merge_sort_top_k' },
                { type: 'p', className: 'description p', content: ' přijme seznam celých čísel ' },
                { type: 'code', content: 'arr' },
                { type: 'p', className: 'description p', content: ' a celé číslo ' },
                { type: 'code', content: 'k' },
                { type: 'p', className: 'description p', content: '. Vaším úkolem je implementovat třídicí algoritmus merge sort a vrátit ' },
                { type: 'code', content: 'k' },
                { type: 'p', className: 'description p', content: ' největších prvků z ' },
                { type: 'code', content: 'arr' },
                { type: 'p', className: 'description p', content: '. Algoritmus merge sort třídí pole s asymptotickou složitostí ' },
                { type: 'code', content: 'O(n log n)' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Vstup: ' },
                { type: 'code', content: 'arr = [3, 1, 5, 6, 8, 2, 9, 4, 7]' },
                { type: 'p', className: 'description p', content: ', ' },
                { type: 'code', content: 'k = 3' },
                { type: 'p', className: 'description p', content: ' Výstup: ' },
                { type: 'code', content: '[9, 8, 7]' },
                { type: 'p', className: 'description p', content: ' V tomto příkladu funkce vrátí tři největší prvky v seznamu, tedy 9, 8 a 7.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Součástí úkolu jsou připravené unit testy, které otestují funkčnost vašeho kódu pro různé vstupy. Unit testy také zkontrolují efektivnost vaší implementace, takže by váš kód měl nejen správně fungovat, ale být i efektivní, aby prošel testy výkonnosti.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení jako soubor Pythonu, který obsahuje funkci ' },
                { type: 'code', content: 'merge_sort_top_k' },
                { type: 'p', className: 'description p', content: ' spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }


            ],
            deadline: "11. 11. 2024",
            points: 5,
            templateLink: "assignments/merge_sort_top_k.py"
        },
        {
            id: 6,
            title: "Úkol 6: Extrakce maxima z MaxHeapu",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu budete implementovat metodu ' },
                { type: 'code', content: 'extract_max' },
                { type: 'p', className: 'description p', content: ' pro třídu ' },
                { type: 'code', content: 'MaxHeap' },
                { type: 'p', className: 'description p', content: '. Tato metoda vrátí maximální prvek haldy a upraví její strukturu tak, aby stále odpovídala pravidlům maximové haldy.' },
                { type: 'h3', className: 'description h3', content: 'Popis funkce:' },
                { type: 'p', className: 'description p', content: 'Metoda ' },
                { type: 'code', content: 'extract_max' },
                { type: 'p', className: 'description p', content: ' by měla odstranit a vrátit maximální prvek v haldě (prvek na vrcholu) a přeuspořádat zbytek haldy tak, aby zůstala v maximovém uspořádání. Po odstranění maximálního prvku je třeba zajistit, aby nový kořen haldy správně "probublal" směrem dolů, aby se obnovilo haldové uspořádání.' },
                { type: 'h3', className: 'description h3', content: 'Příklad:' },
                { type: 'p', className: 'description p', content: 'Předpokládejme, že máme následující MaxHeap reprezentovaný polem: ' },
                { type: 'code', content: '[20, 10, 15, 8, 9, 5, 12]' },
                { type: 'p', className: 'description p', content: '. Po zavolání ' },
                { type: 'code', content: 'extract_max' },
                { type: 'p', className: 'description p', content: ' by se měl vrátit prvek ' },
                { type: 'code', content: '20' },
                { type: 'p', className: 'description p', content: ' a halda by měla být upravena na nové maximové uspořádání.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Součástí úkolu jsou připravené unit testy, které otestují správnost vaší implementace na různých případech, včetně prázdné haldy, haldy s jedním prvkem a komplexnějších struktur haldy. Ujistěte se, že všechny testy prochází, abyste si byli jistí správností implementace.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení jako soubor Pythonu obsahující třídu ' },
                { type: 'code', content: 'MaxHeap' },
                { type: 'p', className: 'description p', content: ' s implementovanou metodou ' },
                { type: 'code', content: 'extract_max' },
                { type: 'p', className: 'description p', content: '. Ujistěte se, že váš kód úspěšně prochází všemi unit testy před odevzdáním. Odevzdejte do Teams.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "24. 11. 2024",
            points: 10,
            templateLink: "assignments/extract_max.py"
        },
        {
            id: 7,
            title: "Úkol 7: Hashovací funkce",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu budete implementovat metodu ' },
                { type: 'code', content: 'hash_function' },
                { type: 'p', className: 'description p', content: ' pro třídu ' },
                { type: 'code', content: 'HashTable' },
                { type: 'p', className: 'description p', content: '. Tato metoda vrátí index v poli, kam se má uložit klíč.' },
                { type: 'h3', className: 'description h3', content: 'Hashovací tabulka' },
                { type: 'p', className: 'description p', content: 'V tomto úkolu máte naimplementovat hashovací funkci pro hashovací tabulku, která ukládá data ve formátu int. Hashovací tabulka je už skoro celá naimplementovaná pomocí odkazů na spojové seznamy, jediné co chybí je dobrá hashovací funkce. Cílem úkolu je tedy vymyslet funkci, která co nejlépe zařídí, aby bylo co nejméně kolizí při vkládání.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Součástí úkolu jsou připravené unit testy, které otestují správnost vaší implementace a hlavně prakticky otestují, zda-li je vaše hashovací funkce dobrá.' },
                { type: 'h3', className: 'description h3', content: 'Nápověda:' },
                { type: 'p', className: 'description p', content: 'Koukněte do slidů z 9. hodiny a také na stranu 269 (resp. 277 v druhém vydání) v Průvodci labirintem algoritmů.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte své řešení do Teams. Ujistěte se, že váš kód úspěšně prochází všemi unit testy před odevzdáním.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "1. 12. 2024",
            points: 5,
            templateLink: "assignments/hash_table.py"
        },

        {
            id: 8,
            title: "Úkol 8: Stupně v grafu",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'Je možné, aby graf obsahoval právě jeden vrchol s lichým stupněm? Proč ano, nebo proč ne? Vysvětlete.' },
                { type: 'h3', className: 'description h3', content: '' },
                { type: 'p', className: 'description p', content: 'Odevzdejte buďto na papíře na hodině, nebo naskenované v Teams.' },
            ],
            deadline: "9. 12. 2024",
            points: 5,
        },

        {
            id: 9,
            title: "Úkol 9: Nejkratší cesta pro koně",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu budete implementovat funkci ' },
                { type: 'code', content: 'bfs_shortest_path' },
                { type: 'p', className: 'description p', content: ' pro nalezení nejkratší cesty na šachovnici mezi dvěma pozicemi. Funkce by měla vrátit seznam pozic, které představují nejkratší cestu mezi počáteční a cílovou pozicí. Můžete využít již předem implementovanou funkci ' },
                { type: 'code', content: 'get_knight_moves' },
                { type: 'p', className: 'description p', content: ' pro získání platných tahů pro koně.' },
                { type: 'h3', className: 'description h3', content: 'Popis úkolu' },
                { type: 'p', className: 'description p', content: 'Vaším úkolem je napsat funkci, která vrátí nejkratší cestu koně mezi dvěma políčky na šachovnici. Funkce ' },
                { type: 'code', content: 'bfs_shortest_path' },
                { type: 'p', className: 'description p', content: ' přijímá počáteční a cílovou pozici na šachovnici a vrací seznam políček, které tvoří nejkratší cestu, včetně startu a cíle. Pokud cesta mezi startem a cílem není možná, funkce vrátí -1.' },
                { type: 'h3', className: 'description h3', content: 'Šachovnice' },
                { type: 'p', className: 'description p', content: 'Šachovnice je defaultně 8x8 pole (ale může mít i jiné rozměry, s tím také musíte počítat), kde každé políčko je reprezentováno dvojicí (x, y), kde x je řádek a y je sloupec. Kůň se pohybuje v osmi směrech a musí dodržovat pravidla pohybu pro tuto figuru.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Součástí úkolu jsou připravené unit testy, které ověří, zda vaše implementace vrací správnou délku cesty a zda každé spojení mezi uzly je platný tah pro koně.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte svou implementaci do Teams. Ujistěte se, že vaše řešení prochází všechny unit testy před odevzdáním.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "15. 12. 2024",
            points: 10,
            templateLink: "assignments/shortest_path_knight.py"
        },

        {
            id: 10,
            title: "Úkol 10: Počet řešení problému N dam",
            descriptionElements: [
                { type: 'p', className: 'description p', content: 'V tomto úkolu budete implementovat funkci ' },
                { type: 'code', content: 'solve_n_queens' },
                { type: 'p', className: 'description p', content: ' pro zjištění počtu možných řešení problému N dam. Vaše funkce by měla přijmout celé číslo ' },
                { type: 'code', content: 'n' },
                { type: 'p', className: 'description p', content: ' a vrátit počet všech možných uspořádání ' },
                { type: 'code', content: 'n' },
                { type: 'p', className: 'description p', content: ' dam na šachovnici velikosti ' },
                { type: 'code', content: 'n x n' },
                { type: 'p', className: 'description p', content: ' tak, aby žádné dvě dámy nebyly v útoku.' },
                { type: 'h3', className: 'description h3', content: '' },
                { type: 'h3', className: 'description h3', content: 'Šachovnice' },
                { type: 'p', className: 'description p', content: 'Šachovnice je ' },
                { type: 'code', content: 'n x n' },
                { type: 'p', className: 'description p', content: ' pole, kde každé políčko může být buď prázdné, nebo obsahovat dámu. Dáma může útočit vodorovně, svisle i diagonálně.' },
                { type: 'h3', className: 'description h3', content: 'Unit Testy:' },
                { type: 'p', className: 'description p', content: 'Součástí úkolu jsou připravené unit testy, které ověří, zda vaše implementace vrací správný počet řešení pro různé hodnoty ' },
                { type: 'code', content: 'n' },
                { type: 'p', className: 'description p', content: '.' },
                { type: 'h3', className: 'description h3', content: 'Odevzdání:' },
                { type: 'p', className: 'description p', content: 'Odevzdejte svou implementaci do Teams. Ujistěte se, že vaše řešení prochází všechny unit testy před odevzdáním.' },
                { type: 'h3', className: 'description h3', content: '' }
            ],
            deadline: "5. 1. 2025",
            points: 5,
            templateLink: "assignments/n_queens_ways_template.py"
        }
    ];

    return (
        <section id="ukoly" className="section p-6">
        <h2 className="text-2xl font-bold mb-6">Úkoly</h2>
        <h3 className="description h3">Pravidla:</h3>
        <ul>
        <li> Úkoly můžete odevzdávat do 23:59 v den odevzdání. Kdyby se ale cokoli dělo (nad něčím jste se zasekli, jste u babičky atd.), rád vám deadline prodloužím. Platí však, že mi o tom musíte napsat před deadlinem. </li>
        <li> Na úkolech můžete spolupracovat a bavit se o jejich řešení, ale platí, že kód píše každý sám. Ne společně. </li>
        <li> Všechny internetové zdroje jsou samozřejmě povoleny. </li>
        <li> Generativní AI modely vám sice zakázat nemohu (ani nemám jak), ale doporučuji vám je nepoužívat. Půjde o základní a zásadní koncepty, které je opravdu dobré si osvojit vlastními silami. </li>
        </ul>

        <div className="space-y-4">
        {homeworks.map((homework) => (
            <HomeworkItem key={homework.id} {...homework} />
        ))}
        </div>
        </section>
    );
};

export default Ukoly;
